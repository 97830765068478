import request from '@/utils/request'

/**
 * spu列表
 * @param {*} params 
 */
export function fetchSpuList(params) {
    return request({
        url: '/StockProductInfo/Search',
        method: 'post',
        data: params
    });
}


export function fetchSpuDetail(id) {
    return request({
        url: '/StockProductInfo/' + id,
        method: 'get'
    });
}


export function fetchBillCode(code) {
    return request({
        url: '/StockProductInfo/GetBillCode?Code=' + (code || ""),
        method: 'get'
    });
}

export function delSpu(id) {
    return request({
        url: '/StockProductInfo/' + id,
        method: 'delete'
    });
}

/**
 * 修改产品SPU信息
 * @param {*} params 
 * @returns 
 */
export function submitSpu(id, params) {
    return request({
        url: '/StockProductInfo/' + (id || ''),
        method: id ? 'put' : 'post',
        data: params
    });
}





/**
 * sku列表
 * @param {*} params 
 */
export function fetchSkuList(params) {
    return request({
        url: '/StockProductDetail/Search',
        method: 'post',
        data: params
    });
}


export function fetchSkuDetail(id) {
    return request({
        url: '/StockProductDetail/' + id,
        method: 'get'
    });
}

export function fetchSkuByDetailCode(DetailCode) {
    return request({
        url: '/StockProductDetail/DetailCode/' + DetailCode,
        method: 'get'
    });
}

/**
 * 修改产品SKU信息
 * @param {*} params 
 * @returns 
 */
export function submitSku(params) {
    let id = (params.id || '');
    return request({
        url: '/StockProductDetail/' + id,
        method: id.length > 0 ? 'put' : 'post',
        data: params
    });
}


export function delSku(id) {
    return request({
        url: '/StockProductDetail/' + id,
        method: 'delete'
    });
}


export function fetchDetailCode(params) {
    return request({
        url: '/StockProductDetail/GetBillCode',
        method: 'get',
        params: params
    });
}



export function getPrevID() {
    return request({
        url: '/StockProductInfo/GetPrevID',
        method: 'get',
    }).then((res) => {
        return res.data;
    });
}