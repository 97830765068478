<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>委外下单</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <el-tabs type="border-card" v-model="tabName" style="max-width: 1200px; margin: 0 auto">
      <el-tab-pane name="default">
        <template #label>
          <span> 单据详情 </span>
        </template>

        <el-form :model="forms" :rules="rules" ref="forms" :label-width="labelWidth">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="单据类型" prop="billType">
                <el-select v-model="forms.billType" placeholder="请选择" @change="chooseOption($event, dict.billTypes)">
                  <el-option v-for="item in dict.billTypes" :key="item.id" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <el-form-item label="单据编号" prop="billCode">
                <el-input v-model="forms.billCode" autocomplete="off" disabled></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="下单渠道" prop="channel">
                <el-select v-model="forms.channel" placeholder="请选择">
                  <el-option v-for="item in dict.channels" :key="item.id" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="单据时间" prop="billDate">
                <el-date-picker v-model="forms.billDate" type="date" placeholder="单据时间" format="YYYY-MM-DD" 
                  :editable="false">
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="款式编号" prop="detailCode">
                <el-select v-model="forms.detailCode" filterable remote reserve-keyword placeholder="请检索输入要下单的款号" :remote-method="searchSkuDetail" :loading="loading" @change="chooseSkuCode($event, dict.details)" style="min-width: 70%">
                  <el-option v-for="item in dict.details" :key="item.id" :value="item.detailCode" :label="'[' +item.detailCode +']' +(item.detailName.length > 15? item.detailName.substring(0, 15) + '…': item.detailName)" :title="item.detailName">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="款式名称" prop="detailName">
                <el-input v-model="forms.detailName" autocomplete="off" disabled style="max-width: 350px"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="对应材质" prop="metalCode">
                <el-select v-model="forms.metalCode" placeholder="请选择" disabled>
                  <el-option v-for="item in dict.metals" :key="item.id" :label="item.value" :value="item.name">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="外部单号" prop="outOrderNo">
                <el-input v-model="forms.outOrderNo" autocomplete="off" style="max-width: 250px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24"> </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="所属品牌" prop="brandName" style="max-width: 85%">
                <el-select v-model="forms.brandName" placeholder="请选择">
                  <el-option v-for="item in dict.brands" :key="item.id" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="品牌款号" prop="brandModelCode" style="max-width: 85%">
                <el-input v-model="forms.brandModelCode" autocomplete="off" style="max-width: 250px"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="款式工厂" prop="factoryName" style="max-width: 85%">
                <el-select v-model="forms.factoryName" placeholder="请选择">
                  <el-option v-for="item in dict.factorys" :key="item.id" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="工厂款号" prop="factoryModelCode" style="max-width: 250px">
                <el-input v-model="forms.factoryModelCode" autocomplete="off">
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="工厂单号" prop="factoryOrderNo">
                <el-input v-model="forms.factoryOrderNo" autocomplete="off" style="max-width: 250px">
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="预计时间" prop="estiDate">
                <el-date-picker v-model="forms.estiDate" type="date" placeholder="预计时间" format="YYYY-MM-DD" :editable="false">
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="订单数量" prop="orderNumber">
                <el-input-number v-model="forms.orderNumber" :precision="2" :step="1" :min="0" :max="10000" />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="预计金额" prop="estiTotalPrice">
                <el-input-number v-model="forms.estiTotalPrice" :precision="2" :step="1" :min="0" :max="10000000" />
              </el-form-item>
            </el-col>

            <el-divider></el-divider>

            <el-form-item>
              <el-button type="primary" @click="submit">提交</el-button>
              <el-button @click="back">取消</el-button>
            </el-form-item>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane name="auditor">
        <template #label>
          <span> 审批详情 </span>
        </template>

        <el-form :label-width="labelWidth">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              开发中...
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane name="workflow">
        <template #label>
          <span> 流程图 </span>
        </template>

        <el-form :label-width="labelWidth">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div id="flowapp">
                <drawapp ref="drawapp1" :data="flowData" :height="500" :width="800" :lang="lang" />
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>
<script>
import { fetchSkuList } from "@/api/product";
import { submitOutOrder, fetchOutOrderDetail } from "@/api/outOrder";
import drawapp from "@/components/flowDraw";
export default {
  name: "OutOrderDetail",
  components: {
    drawapp,
  },
  data() {
    return {
      tabName: "default",
      dict: {
        brands: [],
        factorys: [],
        metal: [],
        billTypes: [],
        details: [],
      },
      forms: {},
      rules: {
        billType: [
          { required: true, message: "请选择单据类型", trigger: "blur" },
        ],
        channel: [
          { required: true, message: "请选择下单渠道", trigger: "blur" },
        ],
        billDate: [
          { required: true, message: "请选择单据时间", trigger: "blur" },
        ],
        detailCode: [
          { required: true, message: "请选择对应材质", trigger: "blur" },
        ],
      },
      labelWidth: "90px",
      loading: false,
      flowData: {},
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    submit() {
      let that = this;

      this.$refs.forms.validate((valid) => {
        if (!valid) return;

        submitOutOrder(this.forms).then((res) => {
          if (res.code === 200) {
            that.$message({
              type: "success",
              message: res.content,
            });
            that.$router.back(-1);
          }
        });
      });
    },
    chooseOption(value, options) {
      let items = options.filter(function (item) {
        return item.name == value;
      });
      if (items && items.length > 0) {
        this.forms.billCode =
          items[0].attribute +
          "-" +
          this.$util.fmtDate(new Date(), "yyyyMMddHHmmss");
      }
    },
    chooseSkuCode(detailCode, options) {
      let items = options.filter(function (item) {
        return item.detailCode == detailCode;
      });
      console.log(items);
      if (items && items.length > 0) {
        this.forms.detailName = items[0].detailName;
        this.forms.metalCode = items[0].metalCode;
        this.forms.brandName = items[0].brandName;
        this.forms.brandCode = items[0].brandCode;
        this.forms.brandModelCode = items[0].brandModelCode;
        this.forms.factoryName = items[0].factoryName;
        this.forms.factoryCode = items[0].factoryCode;
        this.forms.factoryModelCode = items[0].factoryModelCode;
      }
    },
    searchSkuDetail(keyword) {
      let that = this;

      that.loading = true;

      let search = {
        columns: [
          { prop: "detailCode", searchable: true },
          { prop: "detailName", searchable: true },
          { prop: "metalCode", searchable: true },
          { prop: "brandName", searchable: true },
          { prop: "brandCode", searchable: true },
          { prop: "brandModelCode", searchable: true },
          { prop: "factoryName", searchable: true },
          { prop: "factoryCode", searchable: true },
          { prop: "factoryModelCode", searchable: true },
          { prop: "id", searchable: false },
        ],
      };
      if (keyword && keyword.length != "") search.keyword = keyword;

      fetchSkuList(search).then((res) => {
        that.loading = false;
        that.dict.details = res.data.data;
      });
    },
  },
  created() { },
  mounted() {
    var that = this;
    this.id = this.$route.query.id;

    this.$kaung.dictionary("stock", "brand").then((res) => {
      this.dict.brands = res;
    });
    dithis.$kaung.dictionarycts("stock", "factory").then((res) => {
      this.dict.factorys = res;
    });
    this.$kaung.dictionary("stock", "metal").then((res) => {
      this.dict.metals = res;
    });

    this.$kaung.dictionary("stock", "channel").then((res) => {
      this.dict.channels = res;
    });

    this.dict.billTypes = [
      { id: "1", name: "备货", value: "备货", attribute: "BH" },
      { id: "2", name: "客订", value: "客订", attribute: "KD" },
    ];

    if (that.id) {
      fetchOutOrderDetail(this.id).then((res) => {
        that.forms = res.data;
      });
    }
  },
  computed: {},
};
</script>