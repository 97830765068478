import request from '@/utils/request'

export function fetchOutOrders(params) {
  return request({
    url: '/StockOutOrder/Search',
    method: 'post',
    data: params
  });
}

export function fetchOutOrderDetail(id) {
  return request({
      url: '/StockOutOrder/' + id,
      method: 'get'
  });
}



export function delOutOrder(id) {
  return request({
    url: '/StockOutOrder/' + id,
    method: 'delete'
  });
}


export function submitOutOrder(data) {
  return request({
    url: '/StockOutOrder/' + (data.id || ''),
    method: data.id ? 'put' : 'post',
    data: data
  });
}